// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Light mode styles */
body {
    background-color: white;
    color: black;
  }
  
  /* Dark mode styles */
  body.dark {
    background-color: #1a202c;
    color: #a0aec0;
  }
  
  .white-bg-important {
    background-color: white !important;
    color: black !important; /* Ensure text color is readable */
  }
  /* Add additional dark mode styles as needed */
    `, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,uBAAuB;IACvB,YAAY;EACd;;EAEA,qBAAqB;EACrB;IACE,yBAAyB;IACzB,cAAc;EAChB;;EAEA;IACE,kCAAkC;IAClC,uBAAuB,EAAE,kCAAkC;EAC7D;EACA,8CAA8C","sourcesContent":["/* Light mode styles */\nbody {\n    background-color: white;\n    color: black;\n  }\n  \n  /* Dark mode styles */\n  body.dark {\n    background-color: #1a202c;\n    color: #a0aec0;\n  }\n  \n  .white-bg-important {\n    background-color: white !important;\n    color: black !important; /* Ensure text color is readable */\n  }\n  /* Add additional dark mode styles as needed */\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
