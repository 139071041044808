import Navbar from "./Navbar";
import Head from "./Head";
import Contact from "./Contact";
import Footer from "./Footer";

export default function HomeView({ toggle }) {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="place-items-center space-x-4 sm:px-4 mx-auto max-w-6xl 2xl:max-w-7xl">
          <Navbar toggle={toggle} />
          <Head />
          <Contact />
        </div>
      </div>
      <div className="w-full border-t dark:text-white dark:bg-gray-950 dark:border-gray-600">
        <div className="place-items-center space-x-4 px-4 mx-auto max-w-6xl 2xl:max-w-7xl">
          <Footer />
        </div>
      </div>
    </div>
  );
}
