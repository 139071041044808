// src/App.js
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeView from "./components/HomeView";
import Dashboard from "./components/Dashboard";
import { useEffect, useState } from "react";
import HelpCenter from "./components/HelpCenter";
import PolyDrug from "./components/PolyDrug";

function App() {
  const [dark, setDark] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("dark") === "true") {
      setDark(true);
    }
    if (
      window.matchMedia("(prefers-color-scheme: dark)").matches &&
      !localStorage.getItem("dark")
    ) {
      setDark(true);
    }
  }, []);

  const toggle = () => {
    localStorage.setItem("dark", !dark);
    setDark(!dark);
  };

  useEffect(() => {
    if (dark) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [dark]);

  return (
    <BrowserRouter>
      <div className="App dark:text-white dark:bg-gray-950">
        <Routes>
          <Route path="/" element={<HomeView toggle={toggle} />} />
          {/*<Route path="/dashboard" element={<Dashboard />} />*/}
          <Route path="/help" element={<HelpCenter />} />
          {/*<Route path="/verify" element={<PolyDrug />} />*/}


        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
