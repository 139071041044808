import {React,useEffect} from "react";
import { useNavigate,Link, } from "react-router-dom";
import { FaRegClock ,FaArrowRightToBracket } from "react-icons/fa6";
import kc from "../keycloak";

export default function Head() {
  const navigate = useNavigate();


  useEffect(() => {
    kc.onAuthSuccess = () => {
      localStorage.setItem('auth', 'true');
      navigate(kc.tokenParsed.email_verified ? "/dashboard" : "/verify");
    };

    if (kc.authenticated) {
    } else {
    }
  }, [navigate]);

  const handleLogin = () => {
    localStorage.setItem('auth', 'true');
    
    kc.login()
      .then(() => {
        // Wait for Keycloak authentication to complete
        kc.onAuthSuccess = () => {
          kc.loadUserInfo().then(userInfo => {
            const emailVerified = userInfo.email_verified;
            const redirectUri = emailVerified ? "/dashboard" : "/verify";

            // Update the login function to use the dynamic redirect URI
            kc.login({ redirectUri: window.location.origin + redirectUri })
              .then(() => {
                navigate(redirectUri);
              })
              .catch((error) => {
                console.error("Keycloak login error:", error);
              });
          }).catch(error => {
            console.error("Error loading user info:", error);
          });
        };
      })
      .catch((error) => {
        console.error("Keycloak login error:", error);
      });
  };
  return (
    <div className="pt-10 dark:bg-gray-950 dark:text-white">
      <div className="my-24 px-4">
        <h1 className="text-3xl sm:text-5xl font-extrabold sm:mb-4">
          Polydrug
        </h1>
        <p className="mb-6 text-gray-600 dark:text-gray-400">
          Designed to bring the largest Polymer database online.
        </p>
        <div className="flex flex-wrap">
          <button disabled
            // onClick={handleLogin}
            className="rounded-lg text-base font-medium bg-blue-700 hover:bg-blue-800 mr-3 px-5 py-2 text-white flex justify-center items-center"
          >
            <FaRegClock className="mr-2" /> Coming Soon
            {/*<FaArrowRightToBracket className="mr-2" /> Sign in*/}
          </button>
          <button
            className="rounded-lg text-base font-medium border border-1 py-2 px-5 hover:bg-gray-50 dark:hover:bg-gray-800"
          >
            <Link to='/help'>Help Center</Link>
          </button>
        </div>
      </div>
    </div>
  );
}
