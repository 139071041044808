import React from "react";
import Navbar from "./Navbar";

export default function HelpCenter() {
  return (
    <div className="flex flex-col min-h-screen dark:bg-gray-800">
      <Navbar />
      <div className="flex-grow p-8 mt-10">
        <div className="container mx-auto bg-white dark:bg-gray-700 shadow-md rounded-lg px-6 py-8">
          <h1 className="text-4xl font-bold mb-6 text-gray-900 dark:text-white">Polydrug Help Center</h1>
          
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Overview</h2>
            <p className="text-lg mb-4 text-gray-800 dark:text-gray-300">
              Polydrug is a web application designed for analyzing chemical compounds and their properties. It allows users to select compounds, choose properties (descriptors), and visualize data in tables, scatter plots, and histograms.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Getting Started</h2>
            <div className="mb-4">
              <h3 className="text-xl font-medium mb-2">Prerequisites</h3>
              <ul className="list-disc list-inside text-gray-800 dark:text-gray-300">
                <li>Supported browsers: Google Chrome, Mozilla Firefox, Microsoft Edge.</li>
                <li>Access credentials provided by your administrator or sign up using the registration link.</li>
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-medium mb-2">Installation</h3>
              <p className="text-lg mb-4 text-gray-800 dark:text-gray-300">
                Polydrug is a web-based application and does not require installation. Simply access it through your preferred web browser.
              </p>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Features</h2>
            <div className="mb-4">
              <h3 className="text-xl font-medium mb-2">Compound Selection</h3>
              <p className="text-lg mb-4 text-gray-800 dark:text-gray-300">Choose from a list of available chemical compounds. Select multiple compounds for analysis.</p>
            </div>
            <div className="mb-4">
              <h3 className="text-xl font-medium mb-2">Property Selection</h3>
              <p className="text-lg mb-4 text-gray-800 dark:text-gray-300">Select desired descriptors (properties) associated with the chosen compounds.</p>
            </div>
            <div className="mb-4">
              <h3 className="text-xl font-medium mb-2">Data Visualization</h3>
              <p className="text-lg mb-4 text-gray-800 dark:text-gray-300">
                <strong>Table View:</strong> Display data in a tabular format with compound names and selected properties.<br />
                <strong>Chart View:</strong> Visualize data using scatter plots or histograms based on selected properties.
              </p>
            </div>
            <div>
              <h3 className="text-xl font-medium mb-2">Export Data</h3>
              <p className="text-lg mb-4 text-gray-800 dark:text-gray-300">Download data in CSV format for further analysis.</p>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Usage</h2>
            <div className="mb-4">
              <h3 className="text-xl font-medium mb-2">Logging In</h3>
              <ol className="list-decimal list-inside text-gray-800 dark:text-gray-300">
                <li>Navigate to the Polydrug application URL.</li>
                <li>Enter your credentials to log in.</li>
                <li>Explore available compounds and properties.</li>
              </ol>
            </div>
            <div className="mb-4">
              <h3 className="text-xl font-medium mb-2">Selecting Compounds and Properties</h3>
              <ol className="list-decimal list-inside text-gray-800 dark:text-gray-300">
                <li>Use the dropdown menus to select compounds and properties.</li>
                <li>Click on "Choose all" to select all available compounds or properties.</li>
              </ol>
            </div>
            <div className="mb-4">
              <h3 className="text-xl font-medium mb-2">Running Analysis</h3>
              <ol className="list-decimal list-inside text-gray-800 dark:text-gray-300">
                <li>Click on the "Run" button to initiate data analysis.</li>
                <li>Data will be fetched and displayed in the selected view (table or chart).</li>
              </ol>
            </div>
            <div>
              <h3 className="text-xl font-medium mb-2">Filtering Data</h3>
              <ol className="list-decimal list-inside text-gray-800 dark:text-gray-300">
                <li>Navigate to the table view.</li>
                <li>Use the filter dropdowns to select a property and enter a value.</li>
                <li>Click on "Apply Filter" to filter data based on selected criteria.</li>
              </ol>
            </div>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">Help and Support</h2>
            <p className="text-lg text-gray-800 dark:text-gray-300">
              If you need assistance or have any questions related to Polydrug, please contact our support team at <a href="mailto:polydrug-support@uni-jena.de" className="text-blue-500">polydrug-support@uni-jena.de</a>.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}
