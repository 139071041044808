import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import kc from "../keycloak";
import { Tooltip } from "react-tooltip";

const Navbar = ({ toggle }) => {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(kc.authenticated);

  useEffect(() => {
    kc.onAuthSuccess = () => {
      setAuthenticated(true);
      localStorage.setItem('auth', 'true');
      navigate(kc.tokenParsed.email_verified ? "/dashboard" : "/verify");
    };

    if (kc.authenticated) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [navigate]);

  const handleLogin = () => {
    localStorage.setItem('auth', 'true');
    
    kc.login()
      .then(() => {
        // Wait for Keycloak authentication to complete
        kc.onAuthSuccess = () => {
          kc.loadUserInfo().then(userInfo => {
            const emailVerified = userInfo.email_verified;
            const redirectUri = emailVerified ? "/dashboard" : "/verify";

            // Update the login function to use the dynamic redirect URI
            kc.login({ redirectUri: window.location.origin + redirectUri })
              .then(() => {
                setAuthenticated(emailVerified);
                navigate(redirectUri);
              })
              .catch((error) => {
                console.error("Keycloak login error:", error);
              });
          }).catch(error => {
            console.error("Error loading user info:", error);
          });
        };
      })
      .catch((error) => {
        console.error("Keycloak login error:", error);
      });
  };

  const handleLogout = () => {
    kc.logout();
    setAuthenticated(false);
  };

  return (
    <header className="bg-gray-50/60 dark:bg-gray-950/60 backdrop-blur fixed w-full z-10 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
      <div className="flex flex-end h-16 place-items-center justify-end space-x-4 px-4 mx-auto max-w-6xl 2xl:max-w-7xl">
        <div className="flex place-items-center justify-start mr-auto">
          <Link className="hover:opacity-80" to={"/"}>
            <div className="flex text-lg font-bold items-center text-gray-900 dark:text-white xl:ml-6 2xl:ml-8">
              <svg className="h-6 mr-2" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24"><path d="m16,9.5c0,1.378-1.121,2.5-2.5,2.5h-5.5v-3c0-1.103.897-2,2-2h3.5c1.379,0,2.5,1.122,2.5,2.5Zm8-4.5v14c0,2.757-2.243,5-5,5H5c-2.757,0-5-2.243-5-5V5C0,2.243,2.243,0,5,0h14c2.757,0,5,2.243,5,5Zm-6,4.5c0-2.481-2.019-4.5-4.5-4.5h-3.5c-2.206,0-4,1.794-4,4v9c0,.553.447,1,1,1s1-.447,1-1v-4h5.5c2.481,0,4.5-2.019,4.5-4.5Z"/></svg>
              <span className="whitespace-nowrap text-2xl">Polydrug</span>
            </div>
          </Link>
        </div>

        <button
          onClick={toggle}
          id="theme-toggle"
          type="button"
          data-tooltip-id="themeToggleTooltip"
          className="text-gray-500 dark:text-gray-400 hover:text-gray-800 hover:bg-gray-200 dark:hover:text-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-2.5"
        >
          <svg
            id="theme-toggle-dark-icon"
            className="w-5 h-5"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path d="M223.5 32C100 32 0 132.3 0 256S100 480 223.5 480c60.6 0 115.5-24.2 155.8-63.4c5-4.9 6.3-12.5 3.1-18.7s-10.1-9.7-17-8.5c-9.8 1.7-19.8 2.6-30.1 2.6c-96.9 0-175.5-78.8-175.5-176c0-65.8 36-123.1 89.3-153.3c6.1-3.5 9.2-10.5 7.7-17.3s-7.3-11.9-14.3-12.5c-6.3-.5-12.6-.8-19-.8z"></path>
          </svg>
          <svg
            id="theme-toggle-light-icon"
            className="w-5 h-5 hidden"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
              fillRule="evenodd"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <Tooltip id="themeToggleTooltip" place="bottom" effect="solid">
          Toggle Dark Mode
        </Tooltip>

        {/*<div>*/}
        {/*  {!authenticated && (*/}
        {/*    <button type="button" className="text-blue-800" onClick={handleLogin}>*/}
        {/*      Login*/}
        {/*    </button>*/}
        {/*  )}*/}

        {/*  {authenticated && (*/}
        {/*    <button type="button" className="text-blue-800" onClick={handleLogout}>*/}
        {/*      Logout*/}
        {/*    </button>*/}
        {/*  )}*/}
        {/*</div>*/}
      </div>
    </header>
  );
};

export default Navbar;
