import React, { useState, useEffect, useMemo, useCallback } from "react";
import Select from 'react-select';
import {   FaChartArea, FaChartBar, FaFileCsv, FaPlay, FaSort, FaTable, FaUserCircle } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import kc from "../keycloak";
import Plot from 'react-plotly.js';
import { CSVLink } from 'react-csv';
import { useTable, useSortBy, usePagination, useGlobalFilter, useFilters } from 'react-table';

const Dashboard = () => {
  const [compounds, setCompounds] = useState([]);
  const [selectedCompounds, setSelectedCompounds] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [properties, setProperties] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [runClicked, setRunClicked] = useState(false);
  const navigate = useNavigate();
  const accessToken = sessionStorage.getItem('accessToken');
  const [darkMode, setDarkMode] = useState(false);
  const [yAxis, setYAxis] = useState(null);
  const [view, setView] = useState('bar');

  useEffect(() => {
    const isDarkMode = sessionStorage.getItem('darkMode') === 'true';
    setDarkMode(isDarkMode);
  }, []);

  useEffect(() => {
    const cachedCompounds = sessionStorage.getItem('compounds');
    if (cachedCompounds) {
      setCompounds(JSON.parse(cachedCompounds));
    } else {
      setLoading(true);
      fetch("http://localhost:8000/compounds/", {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
        .then(response => response.json())
        .then(data => {
          setCompounds(data);
          sessionStorage.setItem('compounds', JSON.stringify(data));
          setLoading(false);
        })
        .catch(e => {
          console.error(e);
          setLoading(false);
        });
    }
  }, [accessToken]);

  useEffect(() => {
    if (selectedCompounds.length > 0) {
      const newProperties = [];
      selectedCompounds.forEach(compound => {
        Object.keys(compound).forEach(prop => {
          if (!newProperties.includes(prop) && prop !== 'id' && prop !== 'PolymerName') {
            newProperties.push(prop);
          }
        });
      });
      setProperties(newProperties);
    } else {
      setProperties([]);
    }
  }, [selectedCompounds]);

  // const compoundOptions = useMemo(() => {
  //   return compounds.map((compound) => ({
  //     value: compound.PolymerName.value,
  //     label: compound.PolymerName.value
  //   }));
  // }, [compounds]);

  // my fix
    const compoundOptions = useMemo(() => {
      console.log("compounds=", compounds)
    return compounds.map((compound) => ({
      value: compound.name,
      label: compound.name
    }));
  }, [compounds]);

  const propertyOptions = useMemo(() => {
    return properties.map(prop => ({
      value: prop,
      label: prop
    }));
  }, [properties]);
  const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={`Search...`}
        className="p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300 bg-white dark:bg-gray-800"
      />
    );
  };

  const handleCompoundsChange = useCallback((selectedOptions) => {
    // setSelectedCompounds(selectedOptions.map((option) => compounds.find((compound) => compound.PolymerName.value === option.value)));
    setSelectedCompounds(selectedOptions.map((option) => compounds.find((compound) => compound.name === option.value)));

    }, [compounds]);

  const handlePropertiesChange = (selectedOptions) => {
    setSelectedProperties(selectedOptions);
  };

  const handleRun = () => {
    setRunClicked(true);

    if (selectedCompounds.length === 0) {
      setError("Please select compounds.");
      return;
    }
    if (selectedProperties.length === 0) {
      setError("Please select properties.");
      return;
    }
    setError(null);

    const newTableData = selectedCompounds.map(compound => {
      // const row = { Compound: compound.PolymerName.value };
      const row = { Compound: compound.name };
      selectedProperties.forEach(prop => {
        row[prop.value] = compound[prop.value]?.value || '';
      });
      return row;
    });

    setTableData(newTableData);
  };

  const handleLogout = () => {
    navigate('/');
    sessionStorage.setItem('auth', false);
    sessionStorage.clear();
    kc.logout();
  };

  const customSelectStyles = darkMode => ({
    control: (provided) => ({
      ...provided,
      backgroundColor: darkMode ? 'black' : 'white',
      borderColor: darkMode ? 'gray' : 'lightgray',
      color: darkMode ? 'white' : 'black'
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: darkMode ? 'black' : 'white',
      color: darkMode ? 'white' : 'black'
    }),
    singleValue: (provided) => ({
      ...provided,
      color: darkMode ? 'white' : 'black'
    })
  });

  const columns = useMemo(
    () => [
      { Header: 'Compound', accessor: 'Compound', Filter: DefaultColumnFilter },
      ...selectedProperties.map(prop => ({ Header: prop.label, accessor: prop.value,Filter: DefaultColumnFilter }))
    ],
    [selectedProperties]
  );

  const tableInstance = useTable(
    { columns, data: tableData,defaultColumn: { Filter: DefaultColumnFilter } },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setGlobalFilter,
    state: { globalFilter,pageIndex }
  } = tableInstance;

  const renderChart = () => {
    if (!yAxis) return <div>Please select a property for the Y-axis.</div>;
  
    const xData = tableData.map(row => row.Compound); // Always use Compounds for the X-axis
    const yData = tableData.map(row => row[yAxis.value]);
  
    // Check if data exists
    if (yData.length === 0 || xData.length === 0) {
      return <div>No data available to display the chart. Please select a valid property.</div>;
    }
  
    switch (view) {
      case 'bar':
        return (
          <Plot
            data={[
              {
                x: xData,  // Compounds
                y: yData,  // Selected property
                type: 'bar',
                marker: { color: 'blue' },
                text: yData.map((value, index) => `Compound: ${xData[index]}, ${yAxis.label}: ${value}`),
                hoverinfo: 'text',
                width: 0.5,
              },
            ]}
            layout={{
              title: `Comparison of ${yAxis.label} Across Compounds`,
              xaxis: { title: 'Compounds' },
              yaxis: { title: yAxis.label, range: [Math.min(...yData), Math.max(...yData)] },
              margin: { t: 50, b: 80, l: 60, r: 20 },
            }}
            style={{ width: '100%', height: '500px' }}
          />
        );
      case 'area':
        return (
          <Plot
            data={[
              {
                x: xData,  // Compounds
                y: yData,  // Selected property
                type: 'scatter',
                mode: 'markers',
                marker: { color: 'blue', size: 8 },
                
                text: xData.map((name, index) => `${name}: ${yAxis.label}=${yData[index]}`),
                hoverinfo: 'text',
              },
            ]}
            layout={{
              title: `${yAxis.label} Across Compounds`,
              xaxis: { title: 'Compounds', range: [Math.min(...xData), Math.max(...xData)] },
              yaxis: { title: yAxis.label, range: [Math.min(...yData), Math.max(...yData)] },
              margin: { t: 50, b: 80, l: 60, r: 20 },
            }}
            style={{ width: '100%', height: '500px' }}
          />
        );
      default:
        return null;
    }
  };
  
  
  
  

  return (
    <div className={`min-h-screen min-w-screen ${darkMode ? 'bg-gray-900' : 'bg-gray-100'} transition-colors duration-300`}>
      <header className="bg-white rounded-lg p-4 mb-4 flex items-center justify-between shadow-lg">
        <Link to="/" className={`flex items-center ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
          <span className="text-3xl font-semibold">Polydrug</span>
        </Link>
        <div className="flex items-center">
          <FaUserCircle className={`text-3xl ${darkMode ? 'text-gray-200' : 'text-gray-900'}`} />
          <button
            className="ml-4 bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-lg transition duration-300"
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </header>

      {loading && (
        <div className="flex justify-center items-center h-64">
          <div className="w-16 h-16 border-4 border-t-transparent border-blue-500 border-solid rounded-full animate-spin"></div>
        </div>
      )}

      {!loading && (
        <main className="mx-auto p-6 lg:p-8">
          <section className="bg-white shadow-lg rounded-lg p-6 mb-6">
            <h2 className="text-2xl font-semibold mb-6 text-gray-900 dark:text-gray-200">Select Compounds and Properties</h2>
            <div className="flex flex-col lg:flex-row gap-8">
              <div className="flex-1">
                <label htmlFor="compounds-select" className={`block font-semibold mb-2 ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
                  Compounds
                </label>
                <Select
  id="compounds-select"
  options={compoundOptions}
  value={selectedCompounds.map((compound) => ({
    // value: compound.PolymerName.value,
    // label: compound.PolymerName.value
    value: compound.name,
    label: compound.name
  }))}
  onChange={handleCompoundsChange}
  styles={customSelectStyles(darkMode)}
  isMulti
  placeholder="Select Compounds..."
/>
                <button
                  onClick={() => setSelectedCompounds(compounds)}
                  className="mt-2 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition duration-300"
                >
                  Select All
                </button>
              </div>
              <div className="flex-1">
                <label htmlFor="properties-select" className={`block font-semibold mb-2 ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
                  Properties
                </label>
                <Select
  id="properties-select"
  options={propertyOptions}
  value={selectedProperties}
  onChange={handlePropertiesChange}
  styles={customSelectStyles(darkMode)}
  isMulti
  placeholder="Select Properties..."
/>
                <button
                  onClick={() => setSelectedProperties(properties.map(prop => ({ value: prop, label: prop })))}
                  className="mt-2 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition duration-300"
                >
                  Choose All
                </button>
              </div>
            </div>
            {error && (
              <div className="mt-4 text-red-600 font-semibold">
                {error}
              </div>
            )}
            <button
              onClick={handleRun}
              className="mt-6 bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg transition duration-300"
            >
              <FaPlay className="inline-block mr-2" /> Run
            </button>
          </section>

          {runClicked && !error && tableData.length > 0 && (
            <>
              <section className="bg-white shadow-lg rounded-lg p-6 ">
                <div className="flex items-center justify-between mb-4">
                  <h2 className="text-2xl font-semibold text-gray-900 dark:text-gray-200">Results</h2>
                  <input
                    value={globalFilter || ""}
                    onChange={e => setGlobalFilter(e.target.value || undefined)}
                    placeholder="Search..."
                    className="p-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300 bg-white dark:bg-gray-800"
                  />
                  <CSVLink
                    data={tableData}
                    filename="results.csv"
                    className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-lg transition duration-300"
                  >
                    <FaFileCsv className="inline-block mr-2" /> Download CSV
                  </CSVLink>
                </div>
                <div className="overflow-x-auto">
                <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50 dark:bg-gray-700">
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th
                              {...column.getHeaderProps(column.getSortByToggleProps())}
                              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider dark:text-gray-300"
                            >
                              {column.render('Header')}
                              <span>
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? ''
                                    : ''
                                  : ''}
                                <FaSort />
                              </span>
                              <div>{column.canFilter ? column.render('Filter') : null}</div> {/* Column filter */}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200 dark:bg-gray-800">
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                              <td
                                {...cell.getCellProps()}
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200"
                              >
                                {cell.render('Cell')}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="flex justify-center mt-4">
  <div className="">
    <button 
      onClick={() => gotoPage(0)} 
      disabled={!canPreviousPage} 
      className={`px-3 py-2 mr-2 rounded-md ${!canPreviousPage ? 'bg-gray-400 text-gray-600' : 'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600'}`}
    >
      First Page
    </button>
    <button 
      onClick={() => previousPage()} 
      disabled={!canPreviousPage} 
      className={`px-3 py-2 mr-2 rounded-md ${!canPreviousPage ? 'bg-gray-400 text-gray-600' : 'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600'}`}
    >
      Previous
    </button>
    <span className="mx-10 text-sm ml-5 text-gray-700 dark:text-gray-300">
    Page{' '}
    <strong>
      {pageIndex + 1} of {pageOptions.length}
    </strong>
  </span>
    <button 
      onClick={() => nextPage()} 
      disabled={!canNextPage} 
      className={`px-3 py-2 mr-2 rounded-md ${!canNextPage ? 'bg-gray-400 text-gray-600' : 'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600'}`}
    >
      Next
    </button>
    <button 
      onClick={() => gotoPage(pageCount - 1)} 
      disabled={!canNextPage} 
      className={`px-3 py-2 rounded-md ${!canNextPage ? 'bg-gray-400 text-gray-600' : 'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-200 hover:bg-gray-300 dark:hover:bg-gray-600'}`}
    >
      Last Page
    </button>
  </div>
  
</div>
              </section>

              <section className="bg-white shadow-lg rounded-lg p-6 mb-5 mt-10">
                <div className="flex items-center mb-4">
                  <h2 className="text-2xl font-semibold text-gray-900 dark:text-gray-200">Chart Visualization</h2>
                  <div className="ml-auto flex items-center">
                    
                    <button onClick={() => setView('bar')} className={`ml-2 px-3 py-2 ${view === 'bar' ? 'bg-blue-600 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-200'} rounded-lg transition duration-300`}>
                      <FaChartBar className="inline-block mr-2" />
                    </button>
                    <button onClick={() => setView('area')} className={`ml-2 px-3 py-2 ${view === 'area' ? 'bg-blue-600 text-white' : 'bg-gray-200 dark:bg-gray-700 text-gray-900 dark:text-gray-200'} rounded-lg transition duration-300`}>
                      <FaChartArea className="inline-block mr-2" />
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4 mb-4">
                  {/* <div>
                    <label htmlFor="x-axis-select" className={`block font-semibold mb-2 ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
                      X Axis
                    </label>
                    <Select
                      id="x-axis-select"
                      options={propertyOptions}
                      onChange={(option) => setXAxis(option)}
                      styles={customSelectStyles(darkMode)}
                      placeholder="Select X Axis..."
                    />
                  </div> */}
                  <div>
                    <label htmlFor="y-axis-select" className={`block font-semibold mb-2 ${darkMode ? 'text-gray-200' : 'text-gray-900'}`}>
                      Axes
                    </label>
                    <Select
                      id="y-axis-select"
                      options={propertyOptions}
                      onChange={(option) => setYAxis(option)}
                      styles={customSelectStyles(darkMode)}
                      placeholder="Select Y Axis..."
                    />
                  </div>
                </div>
                <div className="">
                  {renderChart()}
                </div>
              </section>
            </>
          )}
        </main>
      )}
    </div>
  );
};

export default Dashboard;
