import React from 'react';
import { useNavigate } from 'react-router-dom';
import kc from '../keycloak';


const PolyDrug = () => {
    const navigate = useNavigate();
    const handleLogout = () => {
        navigate('/');
        localStorage.setItem('auth', false);
        kc.logout();
      };
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-center">
      <h1 className="text-6xl font-bold text-blue-600 mb-6">Hallo</h1>
      <p className="text-xl mb-2">
        Thank you for your interest in trying out the PolyDrug.
      </p>
      <p className="text-md mb-6">
        The service is currently open for early access (Beta test). Please contact us to get access. You can also reply directly to the verification email.
      </p>
      <button className="bg-blue-600 text-white font-semibold py-2 px-4 rounded mb-6" onClick={handleLogout}>
        Sign out
      </button>
      <div className="border-t border-gray-300 pt-4">
        <h2 className="text-2xl font-semibold">PolyDrug</h2>
        <p>Friedrich Schiller University Jena</p>
        <p>Otto Schott Institute of Materials Research</p>
        <p>Löbdergraben 32</p>
        <p>07743 Jena, Germany</p>
        <a href="mailto:polydrug-support@uni-jena.de" className="text-blue-600 underline">
        polydrug-support@uni-jena.de
        </a>
      </div>
    </div>
  );
};

export default PolyDrug;