import React from "react";
export default function Footer() {
  return (
    <div className="border-t dark:text-white dark:bg-gray-950 dark:border-gray-600">
      <footer className="">
        <div className="flex flex-col-reverse flex-wrap sm:flex-row sm:flex-nowrap mx-auto items-center justify-between">
          <div className="text-xs text-gray-900 dark:text-white text-center sm:text-right">
            Copyright © 2024 Computational Materials Science Group
          </div>
          <div className="text-xs">
            
          </div>
        </div>
      </footer>
    </div>
  );
}
