import Keycloak from 'keycloak-js';

let initOptions = {
  url: 'https://accounts.polydrug.uni-jena.de/',
  realm: 'polydrug',
  clientId: 'poly',
  onLoad: 'check-sso', // check-sso | login-required
  KeycloakResponseType: 'code',

  // silentCheckSsoRedirectUri: (window.location.origin + "/silent-check-sso.html")
}

let kc = new Keycloak(initOptions);

kc.init({
  onLoad: initOptions.onLoad,
  KeycloakResponseType: 'code',
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html", checkLoginIframe: false,
  pkceMethod: 'S256'
}).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    localStorage.setItem('accessToken', kc.token);
    localStorage.setItem('vueRefreshToken', kc.refreshToken);
    localStorage.setItem('idToken', kc.idToken);
    localStorage.setItem('auth',true)
    console.info("Authenticated");
    console.log('Keycloak', kc)
    kc.onTokenExpired = () => {
      console.log('token expired')
    }
  }
}, () => {
  console.error("Authenticated Failed");
});

export default kc